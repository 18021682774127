import React from "react";
import "./Advantage.scss";
import Image from "./Image";

function Advantage({ img, headline, text }) {
  return (
    <div className="Advantage">
      <div className="Advantage-Text">
        <h2>{headline}</h2>
        {text}
      </div>
      <div className="Advantage-Image">
        <Image src={img} width={650} height={650} crop alt={"Vorteil 1"} />
      </div>
    </div>
  );
}

export default Advantage;
