import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import MapMarkerPopup from "./MapMarkerPopup";
import { getBaseUrl } from "../helpers/getBaseUrl";

let userInputTimeout = false;

export const generateMarker = (data, id, type, action) => {
  const baseUrl = getBaseUrl(true, "/img/");

  const partnerImage = `${baseUrl}assets/maps_red.png?w=64`;
  const firmImage = `${baseUrl}assets/maps_blue.png?w=64`;
  const mixedImage = `${baseUrl}assets/maps_black.png?w=64`;

  let icon = mixedImage;
  // let icon;

  // console.log("data in generateMarker", data);

  if (data.partners.length && data.firms.length) {
    icon = mixedImage;
  } else if (data.partners.length) {
    icon = partnerImage;
  } else if (data.firms.length) {
    icon = firmImage;
  }

  return {
    position: {
      lat: data.location.lat,
      lng: data.location.lng,
    },
    icon: {
      url: icon,
      scaledSize: { width: 32, height: 32 },
    },
    data: {
      ...data,
      type: type,
      id: id,
      action,
    },
  };
};

export const generateListItem = (data, type, action) => {
  return {
    data: {
      type: type,
      label: data.title,
      street: data.street,
      zipCity: `${data.zip} ${data.city}`,
      image: data.logo,
      id: data.id,
      marker: data.marker,
      bikes: data.bikes,
      instagram: data.instagram,
      linkedin: data.linkedin,
      facebook: data.facebook,
      homepage: data.homepage,
      action,
    },
  };
};

const Map = ({
  initialMarkers = [],
  openedWindow = false,
  setOpenedWindow,
  bundle = false,
  center,
  setCenter,
  zoom,
  setZoom,
}) => {
  const [activeInfoWindow, setActiveInfoWindow] = useState("");
  const [markers, setMarkers] = useState(initialMarkers);

  const [mapref, setMapRef] = React.useState(null);

  useEffect(() => {
    if (markers.length !== initialMarkers.length) {
      setMarkers(initialMarkers);
    }

    if (openedWindow !== false) {
      setActiveInfoWindow(openedWindow);
    } else {
      setActiveInfoWindow("");
    }

    if (mapref) {
      mapref.setCenter(center);
      mapref.setZoom(zoom);
    }
  }, [initialMarkers, markers, openedWindow, center, mapref, zoom]);

  const handleOnLoad = (map) => {
    setMapRef(map);
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const mapClicked = (event) => {
    console.log("clicked Map");
    console.log(event.latLng.lat(), event.latLng.lng());
  };

  const onMapMoved = (event) => {
    if (userInputTimeout) {
      clearTimeout(userInputTimeout);
    }

    if (mapref) {
      userInputTimeout = setTimeout(() => {
        const newCenter = mapref.getCenter();
        if (center.lat !== newCenter.lat()) {
          setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
        }

        if (zoom !== mapref.getZoom()) {
          setZoom(mapref.getZoom());
        }
        userInputTimeout = false;
      }, 500);
    }
  };

  const markerClicked = (marker, index) => {
    console.log("marker clicked");
    setOpenedWindow(marker.data.id);
    setCenter(marker.position);
    setZoom(14);
  };

  const markerDragEnd = (marker, index) => {
    console.log("marker Dragged");
    console.log(marker, index);
  };

  const createKey = (location, type, id) => {
    return location.lat + location.lng + type + id;
  };

  // https://console.cloud.google.com/project/_/google/maps-apis/credentials?_ga=2.167012153.686189815.1654257038-591548673.1654257038
  if (bundle) {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={window.innerWidth < 400 ? 6 : 7}
        onClick={mapClicked}
      >
        <MarkerClusterer minimumClusterSize={8}>
          {(clusterer) =>
            markers.map((marker, index) => (
              <Marker
                key={createKey(
                  marker.position,
                  marker.data.type,
                  marker.data.id
                )}
                position={marker.position}
                clusterer={clusterer}
                label={marker.label}
                draggable={marker.draggable}
                icon={marker.icon}
                onDragEnd={(event) => markerDragEnd(marker, index)}
                onClick={(event) => markerClicked(marker, index)}
              >
                {activeInfoWindow === index && (
                  <InfoWindow
                    position={marker.position}
                    onCloseClick={() => {
                      console.log("closing", marker);
                    }}
                  >
                    <MapMarkerPopup marker={marker} />
                  </InfoWindow>
                )}
              </Marker>
            ))
          }
        </MarkerClusterer>
        ))}
      </GoogleMap>
    );
  }

  // console.log("marker", markers);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onClick={mapClicked}
      onCenterChanged={onMapMoved}
      onZoomChanged={onMapMoved}
      onLoad={handleOnLoad}
    >
      {markers.map((marker, index) => (
        <Marker
          key={marker.data.id}
          position={marker.position}
          label={marker.label}
          draggable={marker.draggable}
          icon={marker.icon}
          onDragEnd={(event) => markerDragEnd(marker, index)}
          onClick={(event) => markerClicked(marker, index)}
        >
          {activeInfoWindow === marker.data.id && (
            <InfoWindow
              position={marker.position}
              onCloseClick={() => {
                console.log("closing", marker);
              }}
            >
              <MapMarkerPopup marker={marker} />
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
};

export default Map;
