import React, { useState } from "react";
import Collapsible from "react-collapsible";
import "./FAQ.scss";
import caret from "../assets/caret.svg";
import AnimatedKringel from "./AnimatedKringel";

function CollapsibleTrigger({ text }) {
  return (
    <div className="Collapsible-Trigger">
      <div className="Collapsible-Trigger-Text">{text}</div>
      <img src={caret} alt="Pfeil zum Öffnen der FAQ" width={24} height={24} />
    </div>
  );
}

function FAQ({ faq, type, seeAllFromStart = false }) {
  const [seeAll, setSeeAll] = useState(seeAllFromStart);

  const FAQs = [];
  if (faq) {
    Object.keys(faq).forEach((key) => {
      if (seeAll || FAQs.length < 5) {
        FAQs.push(
          <Collapsible
            key={`faq_${faq[key].title}`}
            trigger={<CollapsibleTrigger text={faq[key].title} />}
          >
            <div dangerouslySetInnerHTML={{ __html: faq[key].body }} />
          </Collapsible>
        );
      }
    });
  }

  return (
    <div className="FAQ AnimatedKringelParent">
      <AnimatedKringel
        text={"FAQ"}
        wordOffsetDesktop={3}
        wordOffsetMobile={9}
        everyNWordsDesktop={14}
        everyNWordsMobile={14}
        marginBottomMobile={"-200px"}
        pathVar={2}
      />

      <div className="FAQ-Content">{FAQs}</div>

      {!seeAllFromStart ? (
        <div className="FAQ-ViewMore">
          <button onClick={() => setSeeAll(!seeAll)} className={"button"}>
            {seeAll ? "Weniger anzeigen" : "Alle anzeigen"}
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default FAQ;
