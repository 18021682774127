import React from "react";
import "./Steps.scss";
import Step from "./Step";
import { getRegisterUrl } from "../helpers/getRegisterUrl";
import { Link } from "react-router-dom";
import AnimatedKringel from "./AnimatedKringel";

function Steps({ steps }) {
  if (!steps) {
    return <div>Laden...</div>;
  }

  return (
    <div className="Steps AnimatedKringelParent">
      <AnimatedKringel text={"Tritt für Tritt"} marginBottomMobile={"-200px"} />
      <div className="Steps-text">
        {steps.map((step, index) => (
          <Step
            headline={step.title}
            number={index + 1}
            text={step.text}
            key={`step_${step.title}`}
          />
        ))}
      </div>
      <div className="Steps-Banner">
        <a
          href={getRegisterUrl()}
          className="button"
          target="_blank"
          rel="noreferrer"
        >
          Abgefahren! Gleich anmelden
        </a>
        <Link to="/ablauf">Detailliertere Infos und Hilfe zur Anmeldung</Link>
      </div>
    </div>
  );
}

export default Steps;
