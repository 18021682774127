import React from "react";
import "./TextContent.scss";
import Image from "./Image";

function TextContent({
  headline,
  text,
  image,
  buttonVisible = false,
  buttonText = "",
  buttonLink = "",
}) {
  let img = <div />;

  img = <Image src={image} width={450} height={650} alt={headline} crop />;
  return (
    <div className="TextContent">
      <div className="TextContent-image">{img}</div>
      <div className="TextContent-text">
        <h2>{headline}</h2>
        <div dangerouslySetInnerHTML={{ __html: text }} />
        {buttonVisible ? (
          <a
            href={buttonLink}
            className="button"
            target="_blank"
            rel="noreferrer"
          >
            {buttonText}
          </a>
        ) : null}
      </div>
    </div>
  );
}

export default TextContent;
