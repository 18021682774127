import React, { Suspense } from "react";
import "./MapPopup.scss";

const MultiMap = React.lazy(() => import("../components/MultiMap"));

function MapPopup({
  open = false,
  closePopup = () => {
    console.log("close popup");
  },
  ...rest
}) {
  if (!open) {
    return null;
  }
  return (
    <div className="MapPopup">
      <div className="MapPopup-Closer" onClick={() => closePopup(false)} />
      <div className="MapPopup-Content">
        {open && (
          <Suspense
            fallback={<div className="suspenseFallback">Loading...</div>}
          >
            <MultiMap {...rest} />
          </Suspense>
        )}
      </div>
    </div>
  );
}

export default MapPopup;
