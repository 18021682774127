import React from "react";
import { Helmet } from "react-helmet";
import Spacer from "../components/Spacer";
import { Link } from "react-router-dom";

const Error404 = () => (
  <div>
    <Helmet>
      <title>404 - Diese Seite konnte nicht gefunden werden | FIRMENRADL</title>
      <meta
        name="description"
        content="Du bekommst dein gewünschtes Marken-Fahrrad, egal ob normales Radl oder E-Bike, bequem im Leasing direkt über deinen Arbeitgeber mittels Gehaltsabzug."
      />
    </Helmet>
    <Spacer />
    <h1>Sorry, diese Seite konnte nicht gefunden werden.</h1>
    <p>
      Bitte versuch es doch noch einmal auf der <Link to={"/"}>Startseite</Link>
    </p>
  </div>
);

export default Error404;
