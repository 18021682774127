import React from "react";
import "./Brand.scss";
import Image from "./Image";
import { getBaseUrl } from "../helpers/getBaseUrl";

function Brand({ name, logo, id, open }) {
  let img = <div />;

  if (logo.indexOf(".svg") > 0) {
    // image is an svg
    const baseUrl = getBaseUrl(true, "/img/");

    img = <img src={`${baseUrl}${logo}`} alt={name} width={150} height={150} />;
  } else if (logo.length > 5) {
    img = (
      <Image
        src={logo}
        width={150}
        height={150}
        alt={name}
        crop={false}
        fetchpriority={"low"}
      />
    );
  }

  return (
    <button className="Brand" href={`#${id}`} onClick={() => open(id)}>
      <div className="Brand-image">{img}</div>
      <div className="Brand-text">
        <h3>{name}</h3>
      </div>
    </button>
  );
}

export default Brand;
