import React, { useEffect, useState } from "react";
import "./Rechner.scss";
import CurrencyInput from "react-currency-input-field";

import Image from "./../components/Image";
import { getRegisterUrl } from "../helpers/getRegisterUrl";
import icon_close from "../assets/icon_close.svg";
import { getBaseUrl } from "../helpers/getBaseUrl";
import Tooltip from "./Tooltip";

let timer = null;

function Rechner() {
  const [einkommen, setEinkommen] = useState(3400);
  const [fahrradPreis, setFahrradPreis] = useState(2500);
  const [laufzeit, setLaufzeit] = useState(24);
  const [versicherung, setVersicherung] = useState(1);
  const [vorsteuerabzug, setVorsteuerabzug] = useState(true);
  const [svAbzug, setSvAbzug] = useState(false);
  const [valueWVO, setValueWVO] = useState(false);

  const [modalVersicherungOpen, setModalVersicherungOpen] = useState(false);
  const [calculationRunning, setCalculationRunning] = useState(false);
  const [calculationParams, setCalculationParams] = useState({});
  const [calculator, setCalculator] = useState({
    ErsparnisInEuro: 902.2877651623385,
    ErsparnisInProzent: 30.076258838744618,
    MonatlicheZahlung: 68.65467645156923,
    MonatlicheZahlungAG: 116.16611326158399,
    Restwert: 450,
    RestwertInProzent: 0.15,
  });

  // get window width and height for image resizing
  let width = window.innerWidth;
  let height = window.innerHeight;

  // get a larger photo for mobile
  if (width < 800) {
    width = width * 2;
  }

  // reducing width and height to a multiple of 64 to achieve less caching and resizing
  width = width - (width % 64);
  height = height - (height % 64);

  let modal = null;

  if (modalVersicherungOpen) {
    modal = (
      <div className={"Rechner-Modal"}>
        <div
          className="Rechner-Modal-Closer"
          onClick={() => setModalVersicherungOpen(false)}
        />
        <div className="Rechner-Modal-Content">
          <button
            className="Rechner-Modal-Close"
            onClick={() => setModalVersicherungOpen(false)}
          >
            <img
              src={icon_close}
              width={24}
              height={24}
              alt={"Fenster Schliessen"}
            />
          </button>
          <h2>WAS BEINHALTEN DIE BEIDEN VERSICHERUNGSPAKETE?</h2>
          <p>
            Es gibt zwei verschiedene Versicherungspakete aus denen du frei
            wählen kannst. Diese Pakete wurden exklusiv für FIRMENRADL generiert
            und finden sich kein weiteres Mal auf dem Markt. Die
            Versicherungspakete sind bereits in der Ersparnis inklusive.
          </p>
          <div className="row">
            <div className="col">
              <h3>Basis-Schutz</h3>
              <h4>
                Reparaturkosten-Übernahme bei Fahrrad-/E-Bike/Pedelec-Defekten
                durch:
              </h4>
              <ul>
                <li>Fall-/Sturzschäden</li>
                <li>Unfallschäden</li>
                <li>Unsachgemäße Handhabung</li>
                <li>Vandalismus</li>
                <li>Feuchtigkeit</li>
                <li>Elektronikschäden</li>
              </ul>
              <br />
              Inklusive: Arbeitslohn und Ersatzteile
              <h4>Bei Totalschaden</h4>
              <ul>
                <li>
                  Vereinbarte Ablösesumme aus dem zugrundeliegenden
                  Leasingvertrag, max. jedoch die Versicherungssumme.
                </li>
              </ul>
              <h4>Diebstahlschutz</h4>
              <ul>
                <li>
                  Vereinbarte Ablösesumme aus dem zugrundeliegenden
                  Leasingvertrag, max. jedoch die Versicherungssumme.
                </li>
                <li>
                  Ersatz der Kosten für die neuen Teile und Einbaukosten bei
                  Teile-Diebstahl.
                </li>
              </ul>
              <h4>Pick-up-Service</h4>
              Kostenübernahme bei Ausfall des Fahrrades/E-Bikes/Pedelecs während
              einer Ausfahrt durch:
              <ul>
                <li>
                  Beschädigung oder Diebstahl des Fahrrades/E-Bikes/Pedelecs
                </li>
                <li>
                  Ausfall des Motors/der Motorunterstützung auf Grund eines
                  Defekts
                </li>
                <li>Mechanischer Mangel durch Ketten oder Rahmenbruch</li>
                <li>Reifenpanne</li>
                <li>Unfall/Sturz</li>
              </ul>
              <h4>Akkuschutz</h4>
              <ul>
                <li>Unsachgemäße Handhabung</li>
                <li>Feuchtigkeit</li>
                <li>Elektronikschäden</li>
              </ul>
              <br />
              Inklusive: Arbeitslohn und Ersatzteile
              <h4>Ausfallschutz für Arbeitgeber</h4>
              Kostenübernahme in Höhe der verbliebenen Leasingraten, max. jedoch
              die Versicherungssumme, bei Ausfall des Arbeitnehmers.
            </div>
            <div className="col">
              <h3>Premium-Schutz </h3>
              <h4>
                Reparaturkosten-Übernahme bei Fahrrad/E-Bike/Pedelec-Defekten
                durch:
              </h4>
              <ul>
                <li>Fall-/Sturzschäden</li>
                <li>Unfallschäden</li>
                <li>Unsachgemäße Handhabung</li>
                <li>Vandalismus</li>
                <li>Feuchtigkeit</li>
                <li>Elektronikschäden</li>
                <li>
                  <em>Verschleiß</em>
                </li>
              </ul>
              Inklusive: Arbeitslohn und Ersatzteile
              <h4>Bei Totalschaden</h4>
              <ul>
                <li>
                  Vereinbarte Ablösesumme aus dem zugrundeliegenden
                  Leasingvertrag, max. jedoch die Versicherungssumme.
                </li>
              </ul>
              <h4>Diebstahlschutz</h4>
              <ul>
                <li>
                  Vereinbarte Ablösesumme aus dem zugrundeliegenden
                  Leasingvertrag, max. jedoch die Versicherungssumme.
                </li>
                <li>
                  Ersatz der Kosten für die neuen Teile und Einbaukosten bei
                  Teile-Diebstahl.
                </li>
              </ul>
              <h4>Pick-up-Service</h4>
              Kostenübernahme bei Ausfall des Fahrrades/E-Bikes/Pedelecs während
              einer Ausfahrt durch:
              <ul>
                <li>
                  Beschädigung oder Diebstahl des Fahrrades/E-Bikes/Pedelecs
                </li>
                <li>
                  Ausfall des Motors/der Motorunterstützung auf Grund eines
                  Defekts
                </li>
                <li>Mechanischer Mangel durch Ketten oder Rahmenbruch</li>
                <li>Reifenpanne</li>
                <li>Unfall/Sturz</li>
              </ul>
              <h4>Akkuschutz</h4>
              <ul>
                <li>
                  <em>Verschleiß, Abnutzung, Alterung</em>
                </li>
                <li>Unsachgemäße Handhabung</li>
                <li>Feuchtigkeit</li>
                <li>Elektronikschäden</li>
              </ul>
              Inklusive: Arbeitslohn und Ersatzteile
              <h4>Ausfallschutz für Arbeitgeber</h4>
              Kostenübernahme in Höhe der verbliebenen Leasingraten, max. jedoch
              die Versicherungssumme, bei Ausfall des Arbeitnehmers.
            </div>
          </div>
        </div>
      </div>
    );
  }

  let image = null;
  if (window.innerWidth < 800) {
    image = (
      <Image
        src={"assets/mood_red_hero_mobile.jpg"}
        width={368}
        height={800}
        crop={true}
        alt={"Hol dir dein Firmenradl!"}
        visibleByDefault
        fetchpriority={"high"}
      />
    );
  } else {
    image = (
      <Image
        src={"assets/mood_red_hero2.jpg"}
        width={width}
        height={height}
        crop={false}
        alt={"Hol dir dein Firmenradl!"}
        visibleByDefault
        fetchpriority={"high"}
      />
    );
  }

  const parseNumber = (number) => {
    let numberClean = number;
    numberClean = numberClean.replaceAll(" ", "");
    numberClean = numberClean.replaceAll(".", "");

    return numberClean;
  };

  useEffect(
    () => {
      const url =
        process.env.NODE_ENV === "development" ||
        window.location.hostname === "firmenradl.danielwinter.at"
          ? // ? "https://bps2.westeurope.cloudapp.azure.com/rest/calculate/leasing"
            // "https://firmenradl.at/rest/calculate/leasing"
            "https://bps2.dccsintra.net/rest/calculate/leasing"
          : "/rest/calculate/leasing";

      const body = {
        Einkommen: einkommen,
        FahrradPreis: fahrradPreis,
        Versicherung: versicherung,
        Laufzeit: laufzeit,
        Vorsteuerabzug: vorsteuerabzug,
        SvAbzug: svAbzug,
        ValueWVO: valueWVO,
      };

      if (JSON.stringify(calculationParams) !== JSON.stringify(body)) {
        setCalculationRunning(true);

        if (timer) {
          // console.log("killing timer", timer);
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          fetch(url, {
            method: "POST",
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((response) => {
              // console.log("ran the calculation with timer", timer);
              if (JSON.stringify(response) !== JSON.stringify(calculator)) {
                if (!response.status) {
                  setCalculator(response);
                  setCalculationRunning(false);
                  setCalculationParams(body);
                } else {
                  console.log("?");
                }

                timer = 0;
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              timer = 0;
            });
        }, 300);
      }
    },
    // ran into a problem with the timeout using usestate, but it works like that, so I will leave that to bet
    // refactored later on.
    // it has to do with the timeout of the response, i need their values, but I don't need them up to date

    [
      einkommen,
      fahrradPreis,
      laufzeit,
      versicherung,
      vorsteuerabzug,
      svAbzug,
      valueWVO,
      calculator,
      calculationParams,
    ]
  );

  return (
    <div className="Rechner">
      {modal}
      <div className="Rechner-Content">
        <div className="Rechner-HeroImage">{image}</div>
        <div className="Rechner-Slider">
          <div className="Bubbles desktop-only">
            <div className="Bubble Big">
              {calculationRunning
                ? "..."
                : calculator.ErsparnisInEuro.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
              <small>sparen</small>
            </div>
            <div className="Bubble Small">
              {calculationRunning
                ? "..."
                : calculator.ErsparnisInProzent.toFixed(0)}
              %<small>günstiger</small>
            </div>
          </div>
        </div>
        <div className="Rechner-Form">
          <h1>
            Ersparnis<span className={"outline"}>Rechner</span>
          </h1>

          <div className="row">
            <div className="col">
              <p className="subline">
                Mit FIRMENRADL kannst du dir ein Fahrrad deiner Wahl stark
                vergünstigt holen, rechne dir gleich jetzt die Ersparnis aus und
                radel los!
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col Tooltip">
              <label htmlFor="fahrradPreis">Fahrrad-Preis</label>
              <CurrencyInput
                id="fahrradPreis"
                name="fahrradPreis"
                placeholder="Preis in EUR"
                defaultValue={fahrradPreis}
                decimalsLimit={2}
                intlConfig={{ locale: "de-DE", currency: "EUR" }}
                className="currency"
                onChange={(e) => {
                  setFahrradPreis(parseFloat(parseNumber(e.target.value)));
                  parseNumber(e.target.value);
                }}
              />
              <Tooltip>
                Gib hier bitte den Fahrrad-Preis inkl. Zubehör und Steuer ein
              </Tooltip>
            </div>

            <div className="col Tooltip">
              <label htmlFor="einkommen">Bruttogehalt / Monat</label>
              <CurrencyInput
                id="einkommen"
                name="einkommen"
                placeholder="Einkommen in EUR"
                defaultValue={einkommen}
                decimalsLimit={2}
                intlConfig={{ locale: "de-DE", currency: "EUR" }}
                className="currency"
                onChange={(e) =>
                  setEinkommen(parseFloat(parseNumber(e.target.value)))
                }
              />
              <Tooltip>
                Gib hier bitte dein tatsächliches Bruttogehalt pro Monat ein
              </Tooltip>
            </div>
          </div>
          {fahrradPreis < 750 || fahrradPreis > 10000 ? (
            <div className="row">
              <div className="col">
                <span className={"text-center"}>
                  Der Fahrradpreis muss zwischen 750 € und 10.000 € liegen
                </span>
              </div>
            </div>
          ) : null}

          <div className="radioGroupContainer Tooltip">
            <label>Laufzeit</label>
            <div className="radioGroup">
              <label className={laufzeit === 24 ? "checked" : "not-checked"}>
                <input
                  type="radio"
                  name={"laufzeit"}
                  checked={laufzeit === 24}
                  onChange={() => setLaufzeit(24)}
                />{" "}
                24 Monate
              </label>
              <label className={laufzeit === 36 ? "checked" : "not-checked"}>
                <input
                  type="radio"
                  name={"laufzeit"}
                  checked={laufzeit === 36}
                  onChange={() => setLaufzeit(36)}
                />{" "}
                36 Monate
              </label>
              <label className={laufzeit === 48 ? "checked" : "not-checked"}>
                <input
                  type="radio"
                  name={"laufzeit"}
                  checked={laufzeit === 48}
                  onChange={() => setLaufzeit(48)}
                />{" "}
                48 Monate
              </label>
            </div>
            <Tooltip>
              Wähle hier bitte deine gewünschte Laufzeit.
              <br />
              Vergewissere dich aber auch ob in
              <br />
              deinem Unternehmen die Laufzeit möglich ist.
            </Tooltip>
          </div>

          <div className="radioGroupContainer Tooltip">
            <Tooltip>
              Wähle hier dein gewünschtes Versicherungspaket.
              <br />
              Die Inhalte findest du unter weitere Infos.
              <br />
              Vergewissere dich auch hier wie die Vorgaben in deinem Unternehmen
              sind.
            </Tooltip>
            <div className="labelContainer">
              <label>Versicherungspaket</label>
              <button onClick={() => setModalVersicherungOpen(true)}>
                Weitere Infos
              </button>
            </div>
            <div className="radioGroup">
              <label className={versicherung === 1 ? "checked" : "not-checked"}>
                <input
                  type="radio"
                  name={"versicherung"}
                  value="1"
                  checked={versicherung === 1}
                  onChange={(e) => setVersicherung(Number(e.target.value))}
                />{" "}
                Basis
              </label>
              <label className={versicherung === 2 ? "checked" : "not-checked"}>
                <input
                  type="radio"
                  name={"versicherung"}
                  value="2"
                  checked={versicherung === 2}
                  onChange={(e) => setVersicherung(Number(e.target.value))}
                />{" "}
                Premium (inkl. Verschleiß)
              </label>
              {/*<label className={versicherung === 3 ? "checked" : "not-checked"}>*/}
              {/*  <input*/}
              {/*    type="radio"*/}
              {/*    name={"versicherung"}*/}
              {/*    value="3"*/}
              {/*    checked={versicherung === 3}*/}
              {/*    onChange={(e) => setVersicherung(Number(e.target.value))}*/}
              {/*  />{" "}*/}
              {/*  Premium++*/}
              {/*</label>*/}
            </div>
          </div>

          <div className="checkboxGroupSet">
            <div className="checkboxGroupContainer Tooltip">
              <label
                htmlFor="vorsteuerabzug"
                onClick={() => {
                  setVorsteuerabzug(!vorsteuerabzug);
                }}
              >
                <input
                  name="vorsteuerabzug"
                  type="checkbox"
                  checked={vorsteuerabzug}
                  onChange={() => {
                    setVorsteuerabzug(!vorsteuerabzug);
                  }}
                />
                Mein Arbeitgeber ist vorsteuerabzugsberechtigt
                {/*Ist das Unternehmen vorsteuerabzugsberechtigt?*/}
              </label>
              <Tooltip>
                Nutze diese Checkbox, wenn dein Arbeitgeber
                vorsteuerabzugsberechtigt ist,
                <br />
                und ihr im Unternehmen den Abzug auch geltend macht.
              </Tooltip>
            </div>

            <div className="checkboxGroupContainer Tooltip">
              <label
                htmlFor="svAbzug"
                onClick={() => {
                  setSvAbzug(!svAbzug);
                }}
              >
                <input
                  name="svAbzug"
                  type="checkbox"
                  checked={svAbzug}
                  onChange={() => {
                    setSvAbzug(!svAbzug);
                  }}
                />
                Ich möchte auch meine Sozialversicherungsbeiträge reduzieren.
                <Tooltip>
                  ACHTUNG: Eine Reduktion der SV-Grundlage kann Auswirkungen auf
                  die Pensionsauszahlung haben.
                </Tooltip>
              </label>
            </div>

            <div className="checkboxGroupContainer Tooltip">
              <label
                htmlFor="valueWVO"
                onClick={() => {
                  setValueWVO(!valueWVO);
                }}
              >
                <input
                  name="valueWVO"
                  type="checkbox"
                  checked={valueWVO}
                  onChange={() => {
                    setValueWVO(!valueWVO);
                  }}
                />
                Restwert laut Werteverordnung
              </label>
              <Tooltip>
                Restwert lt. Sachbezugswerteverordnung ( 16% / 32% / 48% )
              </Tooltip>
            </div>
          </div>

          <div className="row summary">
            <div className="col Tooltip">
              {calculationRunning
                ? "..."
                : calculator.MonatlicheZahlung.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
              <br />
              <small>Monatliche Rate nach Gehaltsumwandlung</small>
              <Tooltip>Monatliche Belastung von deinem Netto Gehalt</Tooltip>
            </div>
            <div className="col">
              {calculationRunning
                ? "..."
                : calculator.Restwert.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}{" "}
              <br />
              <small>
                Restwert (
                {calculationRunning
                  ? "..."
                  : (calculator.RestwertInProzent * 100).toLocaleString(
                      "de-AT"
                    )}
                %)
              </small>
            </div>
            <div className="col Tooltip">
              {calculationRunning
                ? "..."
                : (
                    calculator.Restwert +
                    calculator.MonatlicheZahlung * laufzeit
                  ).toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}{" "}
              <br />
              <small>Gesamtpreis</small>
              <Tooltip>
                Gesamtsumme aus den <br />
                {laufzeit} monatlichen Raten je{" "}
                {calculationRunning
                  ? "..."
                  : calculator.MonatlicheZahlung.toLocaleString("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    })}{" "}
                + Restwert (
                {calculationRunning
                  ? "..."
                  : calculator.Restwert.toLocaleString("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    })}
                )
              </Tooltip>
            </div>
          </div>

          <div className="BubblesContainer mobile-only">
            <div className="Bubbles mobile-only">
              <div className="Bubble Big">
                {calculationRunning
                  ? "..."
                  : calculator.ErsparnisInEuro.toLocaleString("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    })}
                <small>sparen</small>
              </div>
            </div>
          </div>

          <a href={getRegisterUrl()} className="button">
            Jetzt{" "}
            {calculationRunning
              ? "..."
              : calculator.ErsparnisInProzent.toFixed(0)}
            % Preisvorteil sichern
          </a>

          <small>
            Dieser Rechner bietet eine Richtlinie wie die Ersparnis des
            FIRMENRADL-Konzeptes aussehen könnte. Der tatsächliche Betrag hängt
            von den tatsächlichen Bezügen und Abzügen des Arbeitnehmers ab. Um
            eine 100&nbsp;% korrekte Auskunft zu erhalten kontaktiere bitte
            deinen Arbeitgeber bzw. die Personalabrechnungsabteilung deines
            Unternehmens.{" "}
            <a
              href={getBaseUrl(
                true,
                "Firmenradl_Rechenbeispiel.pdf",
                false,
                true
              )}
              target="_blank"
              rel="noreferrer"
            >
              Rechenbeispiel
            </a>
          </small>
        </div>
      </div>
    </div>
  );
}

export default Rechner;
