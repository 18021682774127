import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import "./Header.scss";

import logo from "../logo.svg";
import logo_red from "../logo_red.svg";
import { getRegisterUrl } from "../helpers/getRegisterUrl";

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

function Header() {
  const scrollDirection = useScrollDirection();
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = (event) => {
    setTimeout(() => setMenuOpen(false), 300);
  };

  const links = [
    <NavLink
      className={(navData) => (navData.isActive ? "active" : "")}
      to="/"
      onClick={(e) => closeMenu(e)}
    >
      Arbeitnehmer
    </NavLink>,
    <NavLink
      className={(navData) => (navData.isActive ? "active" : "")}
      to="/arbeitgeber"
      onClick={(e) => closeMenu(e)}
    >
      Arbeitgeber
    </NavLink>,
    <NavLink
      className={(navData) => (navData.isActive ? "active" : "")}
      to="/haendler"
      onClick={(e) => closeMenu(e)}
    >
      Radl-Händler
    </NavLink>,
    <NavLink
      className={(navData) => (navData.isActive ? "active" : "")}
      to="/marken"
      onClick={(e) => closeMenu(e)}
    >
      Marken
    </NavLink>,
    <NavLink
      className={(navData) => (navData.isActive ? "active" : "")}
      to="/ablauf"
      onClick={(e) => closeMenu(e)}
    >
      Ablauf
    </NavLink>,
    <a
      href={getRegisterUrl()}
      target="_blank"
      className="button"
      rel="noreferrer"
      onClick={(e) => closeMenu(e)}
    >
      Login & Registrierung
    </a>,
  ];

  return (
    <header
      className={`Header ${scrollDirection === "down" ? "hide" : "show"}`}
    >
      <div className="content">
        <NavLink
          className={(navData) => (navData.isActive ? "active" : "")}
          to="/"
        >
          <picture className={"logo"}>
            <img
              src={logo}
              alt="Logo von Firmenradl.at"
              title="Logo von Firmenradl.at"
              className={"white"}
              width={152}
            />
            <img
              src={logo_red}
              alt="Logo von Firmenradl.at"
              title="Logo von Firmenradl.at"
              className={"red"}
              width={152}
            />
          </picture>
        </NavLink>

        <nav className={"desktop"}>
          <ul>
            {links.map((link, index) => (
              <li key={`link_desktop_${index}`}>{link}</li>
            ))}
          </ul>
        </nav>
        <nav className={"mobile"}>
          <Menu
            width={"100vw"}
            right
            isOpen={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
          >
            {links.map((link) => link)}
          </Menu>
        </nav>
      </div>
    </header>
  );
}

export default Header;
