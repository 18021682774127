export function getBaseUrl(
  full = true,
  subDir = "",
  nav = false,
  forceFull = false
) {
  // const appendix = process.env.NODE_ENV === "development" ? "/" : "/cms/";
  const appendix = "/cms/";

  // console.log(subDir.)
  if (subDir.startsWith("/", 0)) {
    subDir = subDir.substring(1);
  }

  let serverUrl =
    process.env.NODE_ENV === "development" ||
    navigator.userAgent === "ReactSnap"
      ? "http://localhost:8080"
      : "https://firmenradl.at";

  if (window.location.hostname === "firmenradl.danielwinter.at") {
    serverUrl = "https://firmenradl.danielwinter.at";
  }

  if (full) {
    if (navigator.userAgent === "ReactSnap" && forceFull === false) {
      return appendix + subDir;
    }
    return serverUrl + appendix + subDir;
  }
  if (nav) {
    return "/cms";
  }

  return appendix + subDir;
}
