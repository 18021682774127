import React, { useState } from "react";
import { filter } from "lodash";
import { FixedSizeList as List } from "react-window";
import Autocomplete from "react-google-autocomplete";
import "./MultiMap.scss";
import Map, { generateListItem, generateMarker } from "./Map";
import MultiMapResult from "./MultiMapResult";
import { googleMapsApiKey } from "../App";
import ic_location from "../assets/ic_location.svg";

// function shuffle(array) {
//   let currentIndex = array.length,
//     randomIndex;
//
//   // While there remain elements to shuffle.
//   while (currentIndex !== 0) {
//     // Pick a remaining element.
//     randomIndex = Math.floor(Math.random() * currentIndex);
//     currentIndex--;
//
//     // And swap it with the current element.
//     [array[currentIndex], array[randomIndex]] = [
//       array[randomIndex],
//       array[currentIndex],
//     ];
//   }
//
//   return array;
// }

function MultiMap({
  firms,
  partners,
  brands,
  markersFromServer,
  partnersOnly = false,
  firmsOnly = false,
  headerText = false,
  hideBanner = false,
}) {
  const [searchText, setSearchText] = useState("");
  const [mode, setMode] = useState(firmsOnly ? "Arbeitgeber" : "Händler");
  const [openedWindow, setOpenedWindow] = useState(false);
  const [center, setCenter] = useState({
    lat: 47.7903272,
    lng: 13.514442,
  });
  const [zoom, setZoom] = useState(window.innerWidth < 400 ? 6 : 7);

  let resultsToDraw = [];

  const markers = [];
  const listItems = [];

  const handleMapPlaceSelected = (place) => {
    setCenter({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    setZoom(12);
  };

  const detectLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setZoom(12);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  if (
    (firms && brands && partners) ||
    (firms && firmsOnly) ||
    (partners && partnersOnly)
  ) {
    // data is now present
    if (searchText.length === 0) {
      if (mode === "Alle" || mode === "Arbeitgeber") {
        Object.keys(firms).forEach((key) => {
          const data = firms[key];
          data.type = "firm";
          resultsToDraw.push(data);
        });
      }

      if (mode === "Alle" || mode === "Händler") {
        Object.keys(partners).forEach((key) => {
          const data = partners[key];
          data.type = "partner";
          resultsToDraw.push(data);
        });
      }
    } else {
      if (mode === "Alle" || mode === "Arbeitgeber") {
        const filteredFirms = filter(firms, function (o) {
          if (o.title.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return true;
          }
          if (o.city.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return true;
          }
          if (o.zip.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return true;
          }
          if (o.street.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return true;
          }
          return false;
        });

        filteredFirms.forEach((data) => {
          resultsToDraw.push({ ...data, type: "firm" });
        });
      }

      if (mode === "Alle" || mode === "Händler") {
        const filteredPartners = filter(partners, function (o) {
          if (o.title.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return true;
          }
          if (o.city.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return true;
          }
          if (o.zip.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return true;
          }
          if (o.street.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return true;
          }
          if (
            o.bikeSearchString
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) >= 0
          ) {
            return true;
          }
          return false;
        });

        filteredPartners.forEach((data) => {
          resultsToDraw.push({ ...data, type: "partner" });
        });
      }
    }

    const markersToDraw = [];

    resultsToDraw.forEach((data, index) => {
      if (!markersToDraw.includes(data.marker)) {
        if (data.marker) {
          markersToDraw.push(data.marker);
        }
      }

      if (data.location.location) {
        listItems.push(
          generateListItem(data, data.type, (id) => {
            setOpenedWindow(id);
            setCenter(data.location.location);
            setZoom(14);
          })
        );
      }
    });

    markersToDraw.forEach((markerToDraw, index) => {
      const data = markersFromServer[markerToDraw];

      if (data.location) {
        markers.push(
          generateMarker(data, markerToDraw, data.type, (id) => {
            setOpenedWindow(id);
            setCenter(data.location);
            setZoom(14);
          })
        );
      }
    });

    // console.log("markersToDraw", markersToDraw);
    // console.log("markers", markers);
  }

  let placeholder = "Nach Händlern, Marken & Arbeitgebern suchen...";
  if (mode === "Händler") {
    placeholder = "Nach Radl-Händlern und Marken suchen...";
  } else if (mode === "Arbeitgeber") {
    placeholder = "Nach Arbeitgebern suchen...";
  }

  let header = (
    <div className="MultiMap-Header">
      <h2>Radl-Händler & Partner</h2>
      <div className="radioGroupContainer">
        <div className="radioGroup">
          <label className={mode === "Alle" ? "checked" : "not-checked"}>
            <input
              type="radio"
              name={"mode"}
              checked={mode === "Alle"}
              onChange={(e) => setMode("Alle")}
            />{" "}
            Alle
          </label>
          <label className={mode === "Händler" ? "checked" : "not-checked"}>
            <input
              type="radio"
              name={"mode"}
              checked={mode === "Händler"}
              onChange={(e) => setMode("Händler")}
            />{" "}
            Radl-Händler
          </label>
          <label className={mode === "Arbeitgeber" ? "checked" : "not-checked"}>
            <input
              type="radio"
              name={"mode"}
              checked={mode === "Arbeitgeber"}
              onChange={(e) => setMode("Arbeitgeber")}
            />{" "}
            Arbeitgeber
          </label>
        </div>
      </div>
    </div>
  );

  if (partnersOnly) {
    header = (
      <div className="MultiMap-Header">
        <h2>{headerText ? headerText : "Radl-Händler"}</h2>
      </div>
    );
  } else if (firmsOnly) {
    header = (
      <div className="MultiMap-Header">
        <h2>{headerText ? headerText : "Partner"}</h2>
      </div>
    );
  }

  return (
    <div className="MultiMap">
      {header}
      <div className="MultiMap-Content">
        <div className="MultiMap-List">
          <div className="row MultiMap-Search">
            <div className="col text-center">
              <div className="place">
                {process.env.NODE_ENV !== "development" ? (
                  <Autocomplete
                    apiKey={googleMapsApiKey}
                    onPlaceSelected={(place) => handleMapPlaceSelected(place)}
                    options={{
                      componentRestrictions: { country: "at" },
                    }}
                    className={"searchbox"}
                    placeholder={"Nach Ort suchen..."}
                  />
                ) : null}

                <button onClick={() => detectLocation()}>
                  <img src={ic_location} alt={"Meine Position erkennen"} />
                </button>
              </div>

              <input
                className="searchbox"
                type="text"
                name={"searchText"}
                placeholder={placeholder}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setOpenedWindow(false);
                }}
              />
            </div>
          </div>
          <div className="MultiMap-Group">
            {listItems.length ? null : (
              <div className={"noSearchResult"}>
                <span>Deine Suche hat leider kein Ergebnis gebracht.</span>
              </div>
            )}
            <List
              className="List"
              height={window.innerHeight * 0.8}
              itemCount={listItems.length}
              itemSize={100}
              width={400}
              itemData={listItems}
            >
              {MultiMapResult}
            </List>
          </div>
        </div>
        <div className="MultiMap-Map">
          <Map
            initialMarkers={markers}
            openedWindow={openedWindow}
            setOpenedWindow={setOpenedWindow}
            center={center}
            setCenter={setCenter}
            zoom={zoom}
            setZoom={setZoom}
          />
        </div>
      </div>
      {!hideBanner ? (
        <div className="MultiMap-Banner">
          <p>
            Dein Radl-Händler oder Arbeitgeber ist noch nicht bei uns
            registriert?
            <br />
            Kein Problem! Kontaktiere uns, wir kümmern uns darum.
          </p>
          <a
            href="mailto:office@firmenradl.at"
            className="button"
            target="_blank"
            rel="noreferrer"
          >
            Kontaktiere uns
          </a>
        </div>
      ) : null}
    </div>
  );
}

export default MultiMap;
