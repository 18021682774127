import React from "react";
import "./MultiMapResult.scss";
import Image from "./Image";
import { getBaseUrl } from "../helpers/getBaseUrl";

function MultiMapResult(props) {
  const { label, street, zipCity, image, type, action, marker } =
    props.data[props.index].data;

  let img = false;

  if (image.indexOf(".svg") > 0) {
    // image is an svg
    const baseUrl = getBaseUrl(true, "/img/");

    img = <img src={`${baseUrl}${image}`} alt={label} />;
  } else if (image.length > 0 && image.indexOf("placeholder") === -1) {
    img = <Image src={image} width={64} height={64} alt={label} crop={false} />;
  }

  return (
    <button
      className={`MultiMapResult ${type}`}
      style={props.style}
      onClick={() => action(marker)}
    >
      <div className="MultiMapResult-text">
        <h4>{label}</h4>
        <div>
          <p>
            {street} <br />
            {zipCity}
          </p>
        </div>
      </div>
      {img === false ? null : <div className="MultiMapResult-image">{img}</div>}
    </button>
  );
}

export default MultiMapResult;
