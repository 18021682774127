import React, { useEffect, useRef, useState } from "react";
import Urlify from "urlify";
import "./AnimatedKringel.scss";

function AnimatedKringel({
  text,
  everyNWordsDesktop = 3,
  everyNWordsMobile = 3,
  wordOffsetDesktop = 2,
  wordOffsetMobile = 2,
  spacerHeight = 300,
  marginBottomDesktop = 0,
  marginBottomMobile = 0,
  color = "#E0334C",
  pathVar = 0,
  scrollSpeedDesktop = 1,
  scrollSpeedMobile = 1,
}) {
  const textPath = useRef(null);
  const [offsetTop, setOffsetTop] = useState(0);

  const urlify = Urlify.create();
  const textId = urlify(text);

  useEffect(() => {
    const onScroll = () => {
      requestAnimationFrame(() => {
        const viewPortOffset = textPath.current.getBoundingClientRect();
        const top = viewPortOffset.top;

        const scrollSpeed =
          window.innerWidth < 800
            ? scrollSpeedMobile * 0.1
            : scrollSpeedDesktop * 0.1;

        textPath.current.setAttribute(
          "startOffset",
          top * scrollSpeed - window.innerHeight / 2
        );

        setOffsetTop(top);
      });
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [offsetTop, scrollSpeedDesktop, scrollSpeedMobile]);

  const textToDisplay = [];
  let measureTextLength = "";
  let i = 0;

  const marginBottom =
    window.innerWidth < 800 ? marginBottomMobile : marginBottomDesktop;
  const wordOffset =
    window.innerWidth < 800 ? wordOffsetMobile : wordOffsetDesktop;
  const everyNWords =
    window.innerWidth < 800 ? everyNWordsMobile : everyNWordsDesktop;

  while (measureTextLength.length < 256) {
    if ((i + wordOffset) % everyNWords === 0) {
      // full fill
      textToDisplay.push(
        <tspan key={`${textId}_${i}`} fill={color} className={"filled"}>
          {text.toUpperCase()}
        </tspan>
      );
    } else {
      // outline
      textToDisplay.push(
        <tspan
          key={`${textId}_${i}`}
          fill={"#fff"}
          stroke={color}
          strokeWidth={0.3}
          className={"outline"}
        >
          {text.toUpperCase()}
        </tspan>
      );
    }

    textToDisplay.push(<tspan key={`${textId}_spacerfor_${i}`}> </tspan>);
    measureTextLength += text;
    i++;
  }

  const paths = [
    <path
      id={textId}
      d="M-0.5 124.499C34 86.4995 87 -14.0005 147.5 35.9995C208 85.9995 235.5 159.999 298 124.499C360.5 89 412 25 433.5 96.5"
      stroke="black"
    />,
    <path
      id={textId}
      d="M729 63.4072L687.172 94.2594C584.792 169.775 446.388 174.066 339.529 105.038L273.513 62.3922C218.168 26.6402 148.24 22.1796 88.8033 50.6096L71.5998 58.8385C26.9646 80.1886 -25.244 78.5885 -68.4889 54.5449C-127.287 21.8537 -200.544 31.4934 -248.891 78.2836L-286.252 114.442C-344.286 170.608 -438.82 161.327 -484.831 94.9475C-522.58 40.4864 -595.056 22.8272 -653.614 53.8223L-693.703 75.0417C-732.883 95.7799 -778.281 101.504 -821.38 91.1395L-1138 15"
      stroke="black"
    />,
    <path
      id={textId}
      d="M-594 55.4072L-552.172 86.2594C-449.792 161.775 -311.388 166.066 -204.529 97.0375L-138.513 54.3922C-83.1678 18.6402 -13.2402 14.1796 46.1967 42.6096L63.4002 50.8385C108.035 72.1886 160.244 70.5885 203.489 46.5449C262.287 13.8537 335.544 23.4934 383.891 70.2836L421.252 106.442C479.286 162.608 573.82 153.327 619.831 86.9475C657.58 32.4864 730.056 14.8272 788.614 45.8223L828.703 67.0417C867.883 87.7799 913.281 93.5038 956.38 83.1395L1273 7"
      stroke="black"
    />,
  ];

  return (
    <div className={"AnimatedKringelContainer"}>
      <div
        className="AnimatedKringelSpacer"
        style={{ height: spacerHeight, marginBottom: marginBottom }}
      />
      <div className={"AnimatedKringel"}>
        <h2>{text.toUpperCase()}</h2>
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin slice"
          viewBox={`0 0 500 177`}
        >
          <title>{text}</title>
          {paths[pathVar]}

          <text
            y="40"
            fontSize="12"
            fontFamily="Radial, Din Pro, Comic Sans MS, Helvetica, Arial"
            fontWeight={700}
            fill={color}
            letterSpacing={0}
          >
            <textPath
              id={`text-path_${textId}`}
              className="text"
              href={`#${textId}`}
              startOffset="0"
              ref={textPath}
            >
              {textToDisplay}
            </textPath>
          </text>
        </svg>
      </div>
    </div>
  );
}

export default AnimatedKringel;
